@import "~@/styles/variables";




































































































.add-rx-header {
  font-size: $f-size-md;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    margin-top: 80px;
  }
}
